<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <span>Rapports > Redevance Patente Vignette</span>
      </div>
    </div>
    <div class="row justify-content-center my-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Redevances des patentes et vignettes
          </div>
        </div>
      </div>
    </div>
    <!-- Boss annee -->
    <div class="row">
      <form class="form-inline mx-auto">
        <div class="form-group">
          <label for="">Période du: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="year.annee_debut"/>
        </div>
        <div class="form-group">
          <label for="">au: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="year.annee_fin"/>
        </div>
        <div class="form-group">
          <button type="button"
                  class="btn btn-info"
                  @click="changeYear()">
            Rechercher
          </button>
        </div>
      </form>
    </div>
    <div class="row my-4">
      <div class="col-md-7">
        <Pie :chartdata="chartDataPieChart"
             :height="270"
             v-if="dataLoaded"></Pie>
      </div>
      <div class="col-md-5">
        <h5 style="color: #0097a9;font-weight: bold;"
            class="text-center">Analyse</h5>
        <p>
          La ressource affectée qui réalise le plus de revenus dans la période du 
          <strong style="color: #0097a9;font-weight: bold;">
            
          </strong> 
          au
          <strong style="color: #0097a9;font-weight: bold;">
            
          </strong> 
          est la ressource <strong style="color: #0097a9;font-weight: bold;">{{ valueMax.libelle }}</strong>  
          avec un montant de 
          <strong style="color: #0097a9;font-weight: bold;">{{ Number(valueMax.realisation).toLocaleString() }}</strong> FCFA de réalisation soit 
          <strong style="color: #0097a9;font-weight: bold;">{{ Number(valueMax.pourcentage).toLocaleString() }}</strong>% 
          de la réalisation totale(
          <strong style="color: #0097a9;font-weight: bold;">{{ Number(total_realisation).toLocaleString() }}</strong> FCFA
          ).
          La ressource qui réalise le moins de revenue est la ressource <strong style="color: #0097a9;font-weight: bold;">{{ valueMin.libelle }}</strong>  
          avec un montant de 
          <strong style="color: #0097a9;font-weight: bold;">{{ Number(valueMin.realisation).toLocaleString() }}</strong> FCFA de réalisation soit 
          <strong style="color: #0097a9;font-weight: bold;">{{ Number(valueMin.pourcentage).toLocaleString() }}</strong>% 
          de la réalisation totale(
          <strong style="color: #0097a9;font-weight: bold;">{{ Number(total_realisation).toLocaleString() }}</strong> FCFA
          ).
        </p>
      </div>
    </div>
    <div class="row">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="th-blue text-center"></th>
            <th class="tht-blue text-center"
                :colspan="ressources[0].annee.length"
                style="border-right: 2px solid #dbdbdb;">Montants (FCFA) de la période en cours</th>
            <th class="tht-blue text-center"
                :colspan="ressources[0].annee.length">Montants (FCFA) de la période antérieure</th>
            <th class="th-blue text-right"></th>
          </tr>
          <tr>
            <th class="th-blue text-center">Mois</th>
            <template v-for="(libelle,libkey) in listRubrique">
              <th class="th-blue text-right"
                  :key="libkey"
                  :style="libkey==(listRubrique.length-1)?'border-right: 2px solid #dbdbdb':''">{{ libelle.libelle}}</th>            
            </template>
            <template v-for="(libelle,libkey) in listRubrique">
              <th class="th-blue text-right"
                  :key="'n-1='+libkey">{{ libelle.libelle }}</th>

            </template>
            <th class="th-blue text-right">Variations</th>
          </tr>
        </thead>
        <tbody v-if="ressources != null">
          <tr v-for="(ressource, rekey) in ressources"
              :key="rekey"
              class="text-center">
            <td>{{ ressource.mois }}</td>
            <td class="text-right"
                v-for="(annee,ankey) in ressource.annee"
                :key="ankey"
                :style="ankey==(listRubrique.length-1)?'border-right: 2px solid #dbdbdb':''">{{ Math.round(Number(annee)).toLocaleString() }}</td>
            <td class="text-right"
                v-for="(annee_n1,an1key) in ressource.annee_n1"
                :key="'n-1='+an1key">{{ Math.round(Number(annee_n1)).toLocaleString() }}</td>
            <td class="text-right">{{ (Math.round(Number(ressource.variation) * 100) / 100).toLocaleString() }}%</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="text-center">
            <td colspan="4">Aucune donnée chargée...</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td class="tht-dark-blue">TOTAL</td>
            <td class="text-right tht-dark-blue"
                v-for="(montant, montkey) in totalMonth.annee"
                :key="montkey"
                :style="montkey==(totalMonth.annee.length-1)?'border-right: 2px solid #dbdbdb':''">{{Math.round(montant).toLocaleString()}}</td>
            <td class="text-right tht-dark-blue"
                v-for="(montant, mont1key) in totalMonth.annee_n1"
                :key="'annee-1='+mont1key">{{Math.round(montant).toLocaleString()}}</td>
            <td class="text-right tht-dark-blue">{{(Math.round(totalMonth.variation*100)/100).toLocaleString()}}%</td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div class="row my-4">
      <div class="col-md-12">
        <h5 style="color: #0097a9;font-weight: bold;"
            class="text-center">Analyse</h5>
        <p>
          Le mois qui a le plus engrenger de fonds de la ressource 
          <strong style="color: #0097a9;font-weight: bold;">{{ valueMax.libelle }}</strong>  
          est le mois de
          <strong style="color: #0097a9;font-weight: bold;">{{ moisMaxN.mois }}</strong> 
          avec un montant de 
          <strong style="color: #0097a9;font-weight: bold;">{{ Number(moisMaxN.montant).toLocaleString() }}</strong> FCFA de réalisation 
          contre une réalisation de  
          <strong style="color: #0097a9;font-weight: bold;">{{ Number(moisMaxN_1.montant).toLocaleString() }}</strong> FCFA 
          pour le même mois l'an passé, soit une variation de   
          <strong style="color: #0097a9;font-weight: bold;">{{ Number(moisMaxN_1.variation).toLocaleString() }}%</strong> 
          ce qui révèle une 
          <span v-if="moisMaxN_1.variation > 0"
                style="color: #0097a9;font-weight: bold;">hausse</span>
          <span v-if="moisMaxN_1.variation == 0"
                style="color: #0097a9;font-weight: bold;">constance</span>
          <span v-if="moisMaxN_1.variation < 0"
                style="color: #0097a9;font-weight: bold;">baisse</span> 
          des activités cette année.
          
        </p>
        <p>
          Le mois qui a le moins engrenger de fonds de la ressource 
          <strong style="color: #0097a9;font-weight: bold;">{{ valueMax.libelle }}</strong>  
          est le mois de
          <strong style="color: #0097a9;font-weight: bold;">{{ moisMinN.mois }}</strong> 
          avec un montant de 
          <strong style="color: #0097a9;font-weight: bold;">{{ Number(moisMinN.montant).toLocaleString() }}</strong> FCFA de réalisation 
          contre une réalisation de  
          <strong style="color: #0097a9;font-weight: bold;">{{ Number(moisMinN_1.montant).toLocaleString() }}</strong> FCFA 
          pour le même mois l'an passé, soit une variation de   
          <strong style="color: #0097a9;font-weight: bold;">{{ Number(moisMinN_1.variation).toLocaleString() }}%</strong> 
          ce qui révèle une 
          <span v-if="moisMinN_1.variation > 0"
                style="color: #0097a9;font-weight: bold;">hausse</span>
          <span v-if="moisMinN_1.variation == 0"
                style="color: #0097a9;font-weight: bold;">constance</span>
          <span v-if="moisMinN_1.variation < 0"
                style="color: #0097a9;font-weight: bold;">baisse</span> 
          des activités cette année.
        </p>
      </div>
    </div>
  </div>
</template>
<style>
.analytique{
  font-size: 0.6em;
}
.bad-news{
  color:#ff0000;
}
.good-news{
  color:#24b13c;
}
</style>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex"
// import BarChart from "@/components/shared/BarChart"
import Pie from "../../shared/PieChart"

export default {
  name: "RapportRedevancePatenteVignette",
  components: {
    // VueHtml2pdf,
    // BarChart,
    Pie,
    // LineGenerator,
  },
  data: () => ({
    year: {
      annee_debut: new Date().getFullYear()+"-01-01",
      annee_fin: new Date().getFullYear()+"-12-31",
      periode:"MENSUELLE",
      base:"SIEGE",
      nature:"Ressources affectées"  
    },
    dataLoaded:false,
    totalMonth:{
      annee:0,
      annee_n1:0,
      variation:0
    },
    valueMax:{
      libelle:"",
      realisation:"",
      pourcentage:"",
      position:0
    },
    valueMin:{
      libelle:"",
      realisation:"",
      pourcentage:"",
      position:0
    },
    
    moisMaxN:{
      montant:0,
      mois:"",
    },
    moisMinN:{
      montant:0,
      mois:"",
    },

    moisMaxN_1:{
      montant:0,
      mois:"",
      variation:0
    },
    moisMinN_1:{
      montant:0,
      mois:"",
      variation:0
    },

    situation_max_periode_en_cours:{
      montant:0,
      mois:""
    },
    
    listRubrique:[],
    total_realisation:0,
    color:"",
    yearData: null,
    ressources: null,
    pourcentage: 0,
    chartDataPieChart: {
      labels: [],
      datasets: [
        {
          label: "Réalisation par ressources affectées (%)",
          data: [],
          backgroundColor: [],
          hoverOffset: 4,
        },
      ],
    },

  }),
  created() {
    this.RessourcesAffectees(this.year)
  },
  watch: {
    ressourcesAffectees(){
      if (this.ressourcesAffectees) {
        console.log(this.ressourcesAffectees)
        this.yearData=[]
        this.total_realisation=0
        var color=[
          "#083291",
          "#910856",
          "#910818",
          "#917a08",
          "#c4b3f5",
          "#a829e3",
          "#292fe3",
          "#29b5e3",
          "#29e392",
          "#1e3b2e",
          "#3b2a1e",
          "#331e3b",
          "#806ca1",
          "#6ca19d",
          "#74a16c",
          "#a1816c",
          "#f2b8b6",
          "#b6f2b8",
          "#b3e6f5",
          "#911108",
        ]
        this.yearData=this.ressourcesAffectees.analyse
        this.chartDataPieChart= {
          labels: [],
          datasets: [
            {
              label: "Réalisation par ressources affectées (%)",
              data: [],
              backgroundColor: [],
              hoverOffset: 4,
            }
          ]
        }
        this.valueMax={
          libelle:this.ressourcesAffectees.analyse[0].rubrique,
          realisation:this.ressourcesAffectees.analyse[0].realisation,
          pourcentage:0,
          position:0
        }
        this.valueMin={
          libelle:this.ressourcesAffectees.analyse[0].rubrique,
          realisation:this.ressourcesAffectees.analyse[0].realisation,
          pourcentage:0,
          position:0
        }
        var i=0
        this.yearData.forEach(analyse => {
          if (this.valueMax.realisation < analyse.realisation) {
            this.valueMax.realisation=analyse.realisation
            this.valueMax.libelle=analyse.rubrique
            this.valueMax.position=i
          }
          if (this.valueMin.realisation > analyse.realisation) {
            this.valueMin.realisation=analyse.realisation
            this.valueMin.libelle=analyse.rubrique
            this.valueMin.position=i
          }
          this.chartDataPieChart.datasets[0].data.push(
            Math.round(Number(analyse.realisation))
          )
          this.chartDataPieChart.datasets[0].backgroundColor.push(color[i] )
          this.chartDataPieChart.labels.push(analyse.rubrique)
          this.total_realisation += analyse.realisation
          i++          
        })
        this.valueMax.pourcentage= this.total_realisation > 0 ?(this.valueMax.realisation*100)/this.total_realisation:0
        this.valueMin.pourcentage= this.total_realisation > 0 ?(this.valueMin.realisation*100)/this.total_realisation:0

        if (this.total_realisation - this.ressourcesAffectees.budget_cible > 0) {
          this.color = "good-news"
        } else {
          this.color = "bad-news"
        }
        var mois=["Janvier","Fevrier","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"]
        this.listRubrique=[]
        this.listRubrique=this.ressourcesAffectees.tableau_n[0].rubriques

        this.ressources=[]
        this.totalMonth={
          annee:[],
          annee_n1:[],
          variation:0
        }
        for (let index = 0; index < this.ressourcesAffectees.tableau_n[0].montants.length; index++) {
          this.totalMonth.annee.push(0)
          this.totalMonth.annee_n1.push(0)
        }
        
        this.moisMaxN.mois=mois[0]
        this.moisMaxN.montant=this.ressourcesAffectees.tableau_n[0].montants[this.valueMax.position]
        this.moisMinN.mois=mois[0]
        this.moisMinN.montant=this.ressourcesAffectees.tableau_n[0].montants[this.valueMax.position]

        this.moisMaxN_1.mois=mois[0]
        this.moisMaxN_1.montant=this.ressourcesAffectees.tableau_n1[0].montants[this.valueMax.position]
        this.moisMinN_1.mois=mois[0]
        this.moisMinN_1.montant=this.ressourcesAffectees.tableau_n1[0].montants[this.valueMax.position]

        for (let index = 0; index < this.ressourcesAffectees.tableau_n.length; index++) {
          this.ressources.push({
            mois : mois[index],
            annee : this.ressourcesAffectees.tableau_n[index].montants,
            annee_n1 : this.ressourcesAffectees.tableau_n1[index].montants,
            variation:0  
          })
          if (this.ressourcesAffectees.tableau_n[index].montants>this.moisMaxN.montant) {
            this.moisMaxN.montant=this.ressourcesAffectees.tableau_n[index].montants[this.valueMax.position]
            this.moisMaxN.mois=mois[index]

            this.moisMaxN_1.montant=this.ressourcesAffectees.tableau_n1[index].montants[this.valueMax.position]
            this.moisMaxN_1.mois=mois[index]
          }
          if (this.ressourcesAffectees.tableau_n[index].montants<this.moisMinN.montant) {
            this.moisMinN.montant=this.ressourcesAffectees.tableau_n[index].montants[this.valueMax.position]
            this.moisMinN.mois=mois[index]

            this.moisMinN_1.montant=this.ressourcesAffectees.tableau_n1[index].montants[this.valueMax.position]
            this.moisMinN_1.mois=mois[index]
          }
          
        }
        this.moisMaxN_1.variation = this.moisMaxN_1.montant != 0 ?((this.moisMaxN.montant/this.moisMaxN_1.montant)-1)*100 : 0
        this.moisMinN_1.variation = this.moisMinN_1.montant != 0 ?((this.moisMinN.montant/this.moisMinN_1.montant)-1)*100 : 0
        
        this.ressources.forEach(ressource => {
          var total_mois_n=0
          var total_mois_n1=0
          for (let index = 0; index < ressource.annee.length; index++) {
            this.totalMonth.annee[index] += ressource.annee[index]
            this.totalMonth.annee_n1[index] += ressource.annee_n1[index]
            total_mois_n += ressource.annee[index]
            total_mois_n1 += ressource.annee_n1[index]
            console.log(ressource.annee[index])
          }  
          ressource.variation = total_mois_n1 != 0 ?((total_mois_n/total_mois_n1)-1)*100 : 0
        })
        this.totalMonth.variation = this.totalMonth.annee_n1[this.totalMonth.annee.length-1] != 0 
          ?((this.totalMonth.annee[this.totalMonth.annee.length-1]/this.totalMonth.annee_n1[this.totalMonth.annee.length-1])-1)*100 : 0
        console.log(this.ressources)
        this.dataLoaded=true
      }
    },

    extYear(){
      if (this.extYear) {
        this.changeYear(this.extYear)
        console.log(this.extYear)
      }
    }
  },
  computed: {
    ...mapGetters(["ressourcesAffectees",])
  },
  methods: {
    ...mapActions(["RessourcesAffectees",]),
    ...mapMutations(["setRessourcesAffectees", "setMsgFailRessourcesAffectees"]),
    changeYear(year) {
      this.setRessourcesAffectees("")
      this.dataLoaded=false
      this.year = year
      this.yearData = null
      this.ressources = null
      // var fin_annee=this.extYear.annee_fin.split("-")
      // var debut_annee=this.extYear.annee_debut.split("-")
      // if (fin_annee[0]==debut_annee[0]) {
      //   this.year.periode="MENSUELLE"
      // }else{
      //   this.year.periode="ANNUELLE"
      // }
      this.RessourcesAffectees(this.year)
    }
  }
}
</script>
